// jQuery PubSub
import 'jquery';

(function($) {
  var o = $({});
  $.subscribe = function() {
    o.on.apply(o, arguments);
  };
  $.unsubscribe = function() {
    o.off.apply(o, arguments);
  };
  $.publish = function() {
    o.trigger.apply(o, arguments);
  };
}(jQuery));

function initGoogleMaps() {
  window.google = google;
  jQuery.publish("gmaps.ready", google);
}
window.initGoogleMaps = initGoogleMaps;

function spiderfier_callback() {
  console.log('OMS Async Loading done!');
  jQuery.publish("oms.ready", google);
}

window.spiderfier_callback = spiderfier_callback;
